import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MapPin, Calendar, MessageCircle, UserPlus } from 'lucide-react';
import { doc, getDoc, collection, query, where, getDocs, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import SocialLinks from './SocialLinks';
import MediaGallery from './MediaGallery';
import toast from 'react-hot-toast';

function UserProfile() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [profileData, setProfileData] = useState<any>(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!userId) return;

      try {
        // Fetch user data
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setProfileData({ id: userDoc.id, ...userDoc.data() });
        }

        // Fetch follow status if logged in
        if (user) {
          const followDoc = await getDoc(doc(db, 'follows', `${user.id}_${userId}`));
          setIsFollowing(followDoc.exists());
        }

        // Fetch follower count
        const followersQuery = query(
          collection(db, 'follows'),
          where('followingId', '==', userId)
        );
        const followersSnap = await getDocs(followersQuery);
        setFollowerCount(followersSnap.size);

        // Fetch following count
        const followingQuery = query(
          collection(db, 'follows'),
          where('followerId', '==', userId)
        );
        const followingSnap = await getDocs(followingQuery);
        setFollowingCount(followingSnap.size);
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast.error('Failed to load profile');
      }
    };

    fetchProfileData();
  }, [userId, user]);

  const handleFollow = async () => {
    if (!user || !profileData) {
      toast.error('Please sign in to follow users');
      return;
    }

    try {
      const followId = `${user.id}_${profileData.id}`;
      const followRef = doc(db, 'follows', followId);

      if (isFollowing) {
        await deleteDoc(followRef);
        setFollowerCount(prev => prev - 1);
        setIsFollowing(false);
        toast.success('Unfollowed user');
      } else {
        await setDoc(followRef, {
          followerId: user.id,
          followingId: profileData.id,
          createdAt: new Date()
        });
        setFollowerCount(prev => prev + 1);
        setIsFollowing(true);
        toast.success('Following user');
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
      toast.error('Failed to update follow status');
    }
  };

  const handleMessage = () => {
    if (!user) {
      toast.error('Please sign in to send messages');
      return;
    }
    navigate('/messages', { state: { userId: profileData?.id } });
  };

  if (!profileData) return null;

  const showJoinDate = !profileData.hideJoinDate;

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Cover Photo */}
        <div className="relative h-64 rounded-xl overflow-hidden mb-4">
          <img
            src={profileData.coverPhoto || 'https://images.unsplash.com/photo-1510784722466-f2aa9c52fff6?auto=format&fit=crop&w=1920&h=400'}
            alt="Cover"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Profile Info */}
        <div className="relative bg-gray-800 rounded-xl shadow-lg p-6 mb-6">
          <div className="flex flex-col md:flex-row md:items-start gap-6">
            <div className="relative -mt-16 md:-mt-20 flex-shrink-0">
              <img
                src={profileData.avatar || 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=150&h=150'}
                alt={profileData.displayName}
                className="w-32 h-32 rounded-xl border-4 border-gray-800 object-cover"
              />
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
                <div>
                  <h1 className="text-2xl font-bold text-white">{profileData.displayName}</h1>
                  <p className="text-gray-400">{profileData.username}</p>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={handleMessage}
                    className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center space-x-2"
                  >
                    <MessageCircle className="h-5 w-5" />
                    <span>Message</span>
                  </button>
                  <button
                    onClick={handleFollow}
                    className={`px-4 py-2 rounded-lg flex items-center space-x-2 transition-colors ${
                      isFollowing
                        ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-gray-700 text-white hover:bg-gray-600'
                    }`}
                  >
                    <UserPlus className="h-5 w-5" />
                    <span>{isFollowing ? 'Following' : 'Follow'}</span>
                  </button>
                </div>
              </div>

              {profileData.bio && (
                <p className="mt-4 text-gray-300">{profileData.bio}</p>
              )}

              <div className="flex flex-wrap gap-6 mt-4">
                <div className="flex items-center space-x-1">
                  <span className="font-semibold text-white">{followerCount}</span>
                  <span className="text-gray-400">Followers</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="font-semibold text-white">{followingCount}</span>
                  <span className="text-gray-400">Following</span>
                </div>
              </div>

              <div className="flex flex-wrap gap-4 mt-4">
                {profileData.location && (
                  <div className="flex items-center text-gray-400">
                    <MapPin className="h-4 w-4 mr-1" />
                    <span>{profileData.location}</span>
                  </div>
                )}
                {showJoinDate && (
                  <div className="flex items-center text-gray-400">
                    <Calendar className="h-4 w-4 mr-1" />
                    <span>Joined {new Date(profileData.createdAt).toLocaleDateString()}</span>
                  </div>
                )}
              </div>

              {/* Social Links */}
              <div className="mt-6">
                <SocialLinks {...profileData.socialLinks} />
              </div>
            </div>
          </div>
        </div>

        {/* Media Gallery */}
        <MediaGallery userId={profileData.id} isOwnProfile={false} />
      </div>
    </div>
  );
}

export default UserProfile;