import React, { useState, useCallback, useRef } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Pin, Trash2 } from 'lucide-react';
import { useDrag } from '@use-gesture/react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import type { Conversation } from '../../types/chat';
import toast from 'react-hot-toast';

interface ConversationListProps {
  conversations: Conversation[];
  activeConversation: Conversation | null;
  onSelectConversation: (conversation: Conversation) => void;
}

export default function ConversationList({
  conversations,
  activeConversation,
  onSelectConversation,
}: ConversationListProps) {
  const { user } = useAuth();
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const dragPositions = useRef<{ [key: string]: number }>({});

  // Sort conversations: pinned first, then by timestamp
  const sortedConversations = conversations.sort((a, b) => {
    if (a.isPinned && !b.isPinned) return -1;
    if (!a.isPinned && b.isPinned) return 1;
    return b.lastMessage.timestamp.getTime() - a.lastMessage.timestamp.getTime();
  });

  const handleDelete = useCallback(async (conversationId: string) => {
    if (!user) return;

    try {
      await deleteDoc(doc(db, 'conversations', conversationId));
      toast.success('Conversation deleted');
    } catch (error) {
      console.error('Error deleting conversation:', error);
      toast.error('Failed to delete conversation');
    }
  }, [user]);

  const bindDrag = useDrag(
    ({ args: [conversationId], active, movement: [x], cancel }) => {
      const position = dragPositions.current[conversationId] || 0;
      const newPosition = position + x;

      // Update position
      dragPositions.current[conversationId] = newPosition;
      setDraggingId(active ? conversationId : null);

      // If dragged far enough right, delete
      if (newPosition < -100) {
        handleDelete(conversationId);
        cancel();
      }
    },
    {
      axis: 'x',
      bounds: { left: -100, right: 0 },
      rubberband: true,
    }
  );

  return (
    <div className="flex-1 overflow-y-auto">
      {sortedConversations.map((conversation) => {
        const isDragging = draggingId === conversation.id;
        const position = dragPositions.current[conversation.id] || 0;

        return (
          <div
            key={conversation.id}
            className="relative"
            {...bindDrag(conversation.id)}
            style={{
              transform: `translateX(${position}px)`,
              transition: isDragging ? 'none' : 'transform 0.2s ease-out',
            }}
          >
            {/* Delete indicator */}
            <div 
              className="absolute inset-y-0 right-0 bg-red-500 flex items-center justify-center px-4"
              style={{
                opacity: Math.min(-position / 100, 1),
              }}
            >
              <Trash2 className="h-5 w-5 text-white" />
            </div>

            {/* Conversation item */}
            <button
              onClick={() => onSelectConversation(conversation)}
              className={`w-full p-4 flex items-start space-x-3 hover:bg-gray-800 transition-colors ${
                activeConversation?.id === conversation.id ? 'bg-gray-800' : ''
              }`}
            >
              <div className="relative flex-shrink-0">
                <img
                  src={conversation.user.avatar}
                  alt={conversation.user.displayName}
                  className="w-12 h-12 rounded-full object-cover"
                />
                {conversation.user.isOnline && (
                  <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 border-2 border-gray-900 rounded-full" />
                )}
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <h3 className="font-medium text-white truncate">
                      {conversation.user.displayName}
                    </h3>
                    {conversation.isPinned && (
                      <Pin className="h-4 w-4 text-indigo-400" />
                    )}
                  </div>
                  <span className="text-xs text-gray-400">
                    {formatDistanceToNow(conversation.lastMessage.timestamp, { addSuffix: true })}
                  </span>
                </div>
                <p className="text-sm text-gray-400 truncate">
                  {conversation.user.username}
                </p>
                <div className="flex items-center justify-between mt-1">
                  <p className="text-sm text-gray-400 truncate">
                    {conversation.lastMessage.text}
                  </p>
                  {!conversation.lastMessage.isRead && (
                    <span className="w-2 h-2 bg-indigo-500 rounded-full" />
                  )}
                </div>
              </div>
            </button>
          </div>
        );
      })}
    </div>
  );
}