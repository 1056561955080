import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Heart, MessageCircle, UserPlus } from 'lucide-react';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';

interface Notification {
  id: string;
  type: 'like' | 'comment' | 'follow';
  userId: string;
  username: string;
  avatar: string;
  content: string;
  timestamp: Date;
  read: boolean;
}

export default function NotificationsDropdown() {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!user) return;

      try {
        const notificationsQuery = query(
          collection(db, 'notifications'),
          where('recipientId', '==', user.id),
          orderBy('timestamp', 'desc'),
          limit(10)
        );

        const snapshot = await getDocs(notificationsQuery);
        const notificationsData: Notification[] = [];

        for (const doc of snapshot.docs) {
          const data = doc.data();
          
          // Fetch user data for each notification
          const userDoc = await getDocs(query(
            collection(db, 'users'),
            where('id', '==', data.userId)
          ));

          if (!userDoc.empty) {
            const userData = userDoc.docs[0].data();
            notificationsData.push({
              id: doc.id,
              type: data.type,
              userId: data.userId,
              username: userData.username,
              avatar: userData.avatar || "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=150&h=150",
              content: data.content,
              timestamp: data.timestamp.toDate(),
              read: data.read,
            });
          }
        }

        setNotifications(notificationsData);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [user]);

  const getIcon = (type: Notification['type']) => {
    switch (type) {
      case 'like':
        return <Heart className="h-4 w-4 text-pink-500" />;
      case 'comment':
        return <MessageCircle className="h-4 w-4 text-blue-500" />;
      case 'follow':
        return <UserPlus className="h-4 w-4 text-green-500" />;
    }
  };

  if (!user) return null;

  return (
    <div className="absolute right-0 mt-2 w-80 bg-gray-800 rounded-lg shadow-lg border border-gray-700 overflow-hidden">
      <div className="p-4 border-b border-gray-700">
        <h3 className="text-lg font-semibold text-white">Notifications</h3>
      </div>
      <div className="max-h-96 overflow-y-auto">
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <Link
              key={notification.id}
              to="#"
              className={`block p-4 hover:bg-gray-700 transition-colors ${
                !notification.read ? 'bg-gray-700/50' : ''
              }`}
            >
              <div className="flex items-start space-x-3">
                <img
                  src={notification.avatar}
                  alt={notification.username}
                  className="w-10 h-10 rounded-full"
                />
                <div className="flex-1 min-w-0">
                  <div className="flex items-center space-x-2">
                    <span className="font-medium text-white">
                      {notification.username}
                    </span>
                    {getIcon(notification.type)}
                  </div>
                  <p className="text-sm text-gray-400">{notification.content}</p>
                  <p className="text-xs text-gray-500 mt-1">
                    {new Date(notification.timestamp).toLocaleDateString(undefined, {
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </p>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="p-4 text-center text-gray-400">
            No notifications yet
          </div>
        )}
      </div>
      <div className="p-4 border-t border-gray-700">
        <Link
          to="/notifications"
          className="block text-center text-sm text-indigo-400 hover:text-indigo-300"
        >
          View all notifications
        </Link>
      </div>
    </div>
  );
}