import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile as updateFirebaseProfile
} from 'firebase/auth';
import { doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import type { SubscriptionTier } from '../types/subscription';
import toast from 'react-hot-toast';
import LoadingPage from '../components/ui/LoadingPage';

interface UserProfile {
  firstName: string;
  lastName: string;
  displayName: string;
  birthday: string;
  bio?: string;
  location?: string;
  avatar?: string;
  coverPhoto?: string;
  socialLinks?: {
    onlyFans?: string;
    patreon?: string;
    twitter?: string;
    instagram?: string;
    website?: string;
  };
  tags?: Array<{ category: string; value: string }>;
}

interface User {
  id: string;
  displayName: string;
  email: string;
  avatar: string;
  coverPhoto?: string;
  subscriptionTier: SubscriptionTier;
  profile?: UserProfile;
}

interface AuthContextType {
  user: User | null;
  login: (email: string, password: string, rememberMe?: boolean) => Promise<void>;
  register: (displayName: string, email: string, password: string, profile?: UserProfile) => Promise<void>;
  logout: () => Promise<void>;
  updateProfile: (profile: Partial<UserProfile>) => Promise<void>;
  checkUsernameAvailability: (username: string) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          // Set up real-time listener for user data
          const unsubscribeUser = onSnapshot(doc(db, 'users', firebaseUser.uid), (doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              setUser({
                id: firebaseUser.uid,
                email: firebaseUser.email!,
                displayName: userData.displayName,
                avatar: userData.avatar || '',
                coverPhoto: userData.coverPhoto || '',
                subscriptionTier: userData.subscriptionTier || 'free',
                profile: userData.profile
              });
            }
          });

          return () => unsubscribeUser();
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUser(null);
      } finally {
        setLoading(false);
        setAuthInitialized(true);
      }
    });

    return () => unsubscribe();
  }, []);

  const register = async (
    displayName: string,
    email: string,
    password: string,
    profile?: UserProfile
  ) => {
    try {
      const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, email, password);

      await updateFirebaseProfile(firebaseUser, {
        displayName: displayName
      });

      const userData = {
        displayName,
        displayNameLower: displayName.toLowerCase(),
        email: email.toLowerCase(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        subscriptionTier: 'free' as const,
        isVerified: false,
        avatar: '',
        coverPhoto: '',
        profile: profile ? {
          ...profile,
          displayName,
        } : {
          displayName,
          firstName: '',
          lastName: '',
          birthday: '',
        }
      };

      await setDoc(doc(db, 'users', firebaseUser.uid), userData);

      setUser({
        id: firebaseUser.uid,
        email: firebaseUser.email!,
        displayName,
        avatar: '',
        subscriptionTier: 'free',
        profile: userData.profile
      });
    } catch (error: any) {
      console.error('Registration error:', error);
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('This email is already registered');
      } else if (error.code === 'auth/invalid-email') {
        throw new Error('Invalid email address');
      } else if (error.code === 'auth/operation-not-allowed') {
        throw new Error('Email/password accounts are not enabled');
      } else if (error.code === 'auth/weak-password') {
        throw new Error('Password should be at least 6 characters');
      }
      throw error;
    }
  };

  const login = async (email: string, password: string, rememberMe = false) => {
    try {
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUser({
          id: firebaseUser.uid,
          email: firebaseUser.email!,
          displayName: userData.displayName,
          avatar: userData.avatar || '',
          coverPhoto: userData.coverPhoto || '',
          subscriptionTier: userData.subscriptionTier || 'free',
          profile: userData.profile
        });

        await setDoc(doc(db, 'users', firebaseUser.uid), {
          lastLogin: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }, { merge: true });
      }
    } catch (error: any) {
      console.error('Login error:', error);
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        throw new Error('Invalid email or password');
      }
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };

  const updateProfile = async (profile: Partial<UserProfile>) => {
    if (!user) return;

    try {
      const userRef = doc(db, 'users', user.id);
      await setDoc(userRef, {
        ...profile,
        updatedAt: new Date().toISOString()
      }, { merge: true });
      
      // Local state update handled by onSnapshot listener
    } catch (error) {
      console.error('Profile update error:', error);
      throw error;
    }
  };

  const checkUsernameAvailability = async (username: string): Promise<boolean> => {
    try {
      const userDoc = await getDoc(doc(db, 'usernames', username.toLowerCase()));
      return !userDoc.exists();
    } catch (error) {
      console.error('Username check error:', error);
      return false;
    }
  };

  // Show loading state only during initial auth check
  if (!authInitialized) {
    return <LoadingPage />;
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      register, 
      logout, 
      updateProfile,
      checkUsernameAvailability
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export type { User, UserProfile };