import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, AlertCircle } from 'lucide-react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth, actionCodeSettings } from '../lib/firebase';
import Button from './ui/Button';
import FormField from './ui/FormField';
import toast from 'react-hot-toast';

const COOLDOWN_TIME = 60; // seconds

export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [lastAttemptTime, setLastAttemptTime] = useState<number | null>(null);

  useEffect(() => {
    // Check if there's a stored cooldown timestamp
    const storedTime = localStorage.getItem('passwordResetLastAttempt');
    if (storedTime) {
      const timeDiff = Math.floor((Date.now() - parseInt(storedTime)) / 1000);
      if (timeDiff < COOLDOWN_TIME) {
        setLastAttemptTime(parseInt(storedTime));
        setCooldown(COOLDOWN_TIME - timeDiff);
      } else {
        localStorage.removeItem('passwordResetLastAttempt');
      }
    }
  }, []);

  useEffect(() => {
    let timer: number;
    if (cooldown > 0) {
      timer = window.setInterval(() => {
        setCooldown(prev => {
          if (prev <= 1) {
            localStorage.removeItem('passwordResetLastAttempt');
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldown]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email.trim()) {
      toast.error('Please enter your email address');
      return;
    }

    if (cooldown > 0) {
      toast.error(`Please wait ${cooldown} seconds before trying again`);
      return;
    }

    setIsSubmitting(true);
    try {
      console.log('Attempting to send password reset email to:', email.trim());
      console.log('Using action code settings:', actionCodeSettings);

      // Send password reset email
      await sendPasswordResetEmail(
        auth,
        email.trim(),
        actionCodeSettings
      );

      toast.success(
        'Password reset email sent! Please check your inbox and spam folder.',
        { duration: 6000 }
      );

      // Show additional spam folder notice
      setTimeout(() => {
        toast('Don\'t see the email? Please check your spam/junk folder', {
          icon: '📧',
          duration: 5000
        });
      }, 1000);

      navigate('/login');
    } catch (error: any) {
      console.error('Detailed error information:', {
        code: error.code,
        message: error.message,
        fullError: error
      });
      
      // Handle specific error cases
      switch (error.code) {
        case 'auth/user-not-found':
          toast.error('No account found with this email address');
          break;
        case 'auth/invalid-email':
          toast.error('Please enter a valid email address');
          break;
        case 'auth/too-many-requests':
          const now = Date.now();
          localStorage.setItem('passwordResetLastAttempt', now.toString());
          setLastAttemptTime(now);
          setCooldown(COOLDOWN_TIME);
          toast.error('Too many attempts. Please try again in 60 seconds.', {
            duration: 5000,
            icon: '⏳'
          });
          break;
        case 'auth/network-request-failed':
          toast.error('Network error. Please check your connection.');
          break;
        case 'auth/missing-android-pkg-name':
          toast.error('Invalid app configuration. Please contact support. (Error: APP-1)');
          break;
        case 'auth/missing-ios-bundle-id':
          toast.error('Invalid app configuration. Please contact support. (Error: APP-2)');
          break;
        case 'auth/invalid-continue-uri':
          toast.error('Invalid redirect configuration. Please contact support. (Error: URL-1)');
          break;
        case 'auth/unauthorized-continue-uri':
          toast.error('Unauthorized redirect URL. Please contact support. (Error: URL-2)');
          break;
        case 'auth/missing-continue-uri':
          toast.error('Missing redirect URL configuration. Please contact support. (Error: URL-3)');
          break;
        case 'auth/invalid-action-code':
          toast.error('Invalid reset link. Please try again.');
          break;
        case 'auth/expired-action-code':
          toast.error('Reset link has expired. Please request a new one.');
          break;
        case 'auth/invalid-recipient-email':
          toast.error('Invalid recipient email address.');
          break;
        case 'auth/missing-iframe-start':
          toast.error('Browser security error. Please try a different browser.');
          break;
        case 'auth/operation-not-allowed':
          toast.error('Password reset is not enabled. Please contact support. (Error: AUTH-1)');
          break;
        case 'auth/internal-error':
          toast.error('Authentication service error. Please try again later. (Error: AUTH-2)');
          break;
        default:
          toast.error(`Failed to send reset email (${error.code || 'unknown error'}). Please try again.`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <Mail className="h-12 w-12 text-indigo-400" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
          Reset your password
        </h2>
        <p className="mt-2 text-center text-sm text-gray-400">
          Enter your email address and we'll send you a link to reset your password
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {cooldown > 0 && (
              <div className="mb-4 p-4 bg-yellow-900/20 border border-yellow-700/50 rounded-lg">
                <div className="flex items-center space-x-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500" />
                  <p className="text-sm text-yellow-200">
                    Please wait {cooldown} seconds before trying again
                  </p>
                </div>
              </div>
            )}

            <FormField
              label="Email address"
              error={null}
              touched={false}
              required
            >
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-600 bg-gray-800 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-gray-100"
                placeholder="you@example.com"
                autoComplete="email"
                required
                disabled={cooldown > 0}
              />
            </FormField>

            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Sending..."
              className="w-full"
              disabled={cooldown > 0 || isSubmitting}
            >
              {cooldown > 0 ? `Wait ${cooldown}s` : 'Send reset link'}
            </Button>

            <div className="text-sm text-center">
              <button
                type="button"
                onClick={() => navigate('/login')}
                className="font-medium text-indigo-400 hover:text-indigo-300"
              >
                Back to login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}