import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Users, Shield, Rocket } from 'lucide-react';

export default function About() {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <div className="max-w-4xl mx-auto px-4 py-12 w-full">
        <Link 
          to="/login" 
          className="inline-flex items-center text-indigo-400 hover:text-indigo-300 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Login
        </Link>
        
        <div className="space-y-8">
          {/* Hero Section */}
          <div className="bg-gradient-to-r from-indigo-600 to-purple-600 rounded-xl p-6">
            <h1 className="text-4xl font-bold text-white mb-2">
              What is Scouted?
            </h1>
            <p className="text-xl text-indigo-100">
              Connect. Collaborate. Create.
            </p>
          </div>
          
          {/* Main Content */}
          <div className="bg-gray-800 rounded-xl p-8">
            <div className="prose prose-invert max-w-none space-y-8">
              {/* Introduction */}
              <p className="text-lg text-gray-300 leading-relaxed">
                Scouted is a professional networking platform designed specifically for adult content creators to connect, collaborate, and grow together. Our platform provides a safe, supportive environment for models to find like-minded creators and build meaningful professional relationships.
              </p>

              {/* Features Grid */}
              <div className="grid md:grid-cols-3 gap-6 pt-4">
                <div className="bg-gray-700/50 p-6 rounded-xl">
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="p-2 bg-indigo-600/20 rounded-lg">
                      <Users className="h-6 w-6 text-indigo-400" />
                    </div>
                    <h3 className="text-xl font-semibold text-white">Professional Networking</h3>
                  </div>
                  <p className="text-gray-300">
                    Connect with creators in your niche, expand your network, and discover new collaboration opportunities.
                  </p>
                </div>

                <div className="bg-gray-700/50 p-6 rounded-xl">
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="p-2 bg-indigo-600/20 rounded-lg">
                      <Shield className="h-6 w-6 text-indigo-400" />
                    </div>
                    <h3 className="text-xl font-semibold text-white">Safe Collaboration</h3>
                  </div>
                  <p className="text-gray-300">
                    Find and connect with potential collaborators in a secure, verified environment designed for adult content creators.
                  </p>
                </div>

                <div className="bg-gray-700/50 p-6 rounded-xl">
                  <div className="flex items-center space-x-3 mb-4">
                    <div className="p-2 bg-indigo-600/20 rounded-lg">
                      <Rocket className="h-6 w-6 text-indigo-400" />
                    </div>
                    <h3 className="text-xl font-semibold text-white">Growth & Visibility</h3>
                  </div>
                  <p className="text-gray-300">
                    Enhance your visibility within the community, share content, and grow your audience through meaningful partnerships.
                  </p>
                </div>
              </div>

              {/* Call to Action */}
              <div className="bg-gradient-to-r from-indigo-600/10 to-purple-600/10 p-6 rounded-xl border border-indigo-500/20 mt-8">
                <p className="text-center text-lg text-gray-300">
                  Join our growing community of professional creators and take your content to the next level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}