import { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  onSnapshot, 
  doc, 
  getDoc, 
  setDoc, 
  serverTimestamp 
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { User } from '../contexts/AuthContext';
import type { Conversation } from '../types/chat';
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';

export function useConversations() {
  const { user } = useAuth();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setConversations([]);
      setIsLoading(false);
      return;
    }

    const conversationsRef = collection(db, 'conversations');
    const conversationsQuery = query(
      conversationsRef,
      where('participants', 'array-contains', user.id),
      orderBy('lastMessageAt', 'desc')
    );

    const unsubscribe = onSnapshot(conversationsQuery, async (snapshot) => {
      try {
        const conversationsData: Conversation[] = [];
        
        for (const docSnapshot of snapshot.docs) {
          const data = docSnapshot.data();
          const otherUserId = data.participants?.find((id: string) => id !== user.id);
          
          if (otherUserId) {
            const userDocRef = doc(db, 'users', otherUserId);
            const userDocSnapshot = await getDoc(userDocRef);
            
            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data();
              conversationsData.push({
                id: docSnapshot.id,
                user: {
                  id: otherUserId,
                  displayName: userData.displayName,
                  username: userData.username || userData.displayName.toLowerCase().replace(/\s+/g, ''),
                  avatar: userData.avatar || "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=150&h=150",
                  isOnline: userData.isOnline || false,
                },
                lastMessage: {
                  text: data.lastMessage || 'Start a conversation',
                  timestamp: data.lastMessageAt?.toDate() || new Date(),
                  isRead: data.lastMessageRead || false,
                },
                isPinned: data.isPinned || false,
              });
            }
          }
        }

        setConversations(conversationsData);
      } catch (error) {
        console.error('Error fetching conversations:', error);
        toast.error('Failed to load conversations');
      } finally {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [user]);

  const initializeConversation = async (otherUserId: string) => {
    if (!user) return null;

    try {
      const conversationId = [user.id, otherUserId].sort().join('_');
      const conversationRef = doc(db, 'conversations', conversationId);
      
      await setDoc(conversationRef, {
        participants: [user.id, otherUserId],
        lastMessageAt: serverTimestamp(),
        createdAt: serverTimestamp(),
        lastMessage: '',
        lastMessageRead: true
      }, { merge: true });

      return conversationId;
    } catch (error) {
      console.error('Error initializing conversation:', error);
      toast.error('Failed to start conversation');
      return null;
    }
  };

  return {
    conversations,
    isLoading,
    initializeConversation
  };
}