import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Camera } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { uploadProfileImage, uploadCoverImage } from '../lib/storage';
import TagSelector from './TagSelector';
import toast from 'react-hot-toast';

interface Tag {
  category: string;
  value: string;
}

export default function EditProfile() {
  const navigate = useNavigate();
  const { user, updateProfile } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    displayName: user?.displayName || '',
    bio: user?.bio || '',
    location: user?.location || '',
    socialLinks: {
      onlyFans: user?.profile?.socialLinks?.onlyFans || '',
      patreon: user?.profile?.socialLinks?.patreon || '',
      twitter: user?.profile?.socialLinks?.twitter || '',
      instagram: user?.profile?.socialLinks?.instagram || '',
      website: user?.profile?.socialLinks?.website || ''
    }
  });

  // Photo upload states
  const [selectedAvatar, setSelectedAvatar] = useState<File | null>(null);
  const [selectedCover, setSelectedCover] = useState<File | null>(null);
  const [avatarPreview, setAvatarPreview] = useState<string>(user?.avatar || '');
  const [coverPreview, setCoverPreview] = useState<string>(user?.coverPhoto || '');
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const coverInputRef = useRef<HTMLInputElement>(null);

  const [selectedTags, setSelectedTags] = useState<Tag[]>(user?.profile?.tags || []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    if (name.startsWith('social.')) {
      const [, network] = name.split('.');
      setFormData(prev => ({
        ...prev,
        socialLinks: {
          ...prev.socialLinks,
          [network]: value
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleFileSelect = async (type: 'avatar' | 'cover', e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        toast.error('Image must be less than 5MB');
        return;
      }

      // Create preview
      const previewUrl = URL.createObjectURL(file);
      
      if (type === 'avatar') {
        if (avatarPreview) URL.revokeObjectURL(avatarPreview);
        setSelectedAvatar(file);
        setAvatarPreview(previewUrl);
      } else {
        if (coverPreview) URL.revokeObjectURL(coverPreview);
        setSelectedCover(file);
        setCoverPreview(previewUrl);
      }
    }
  };

  const handleTagSelect = (tag: Tag) => {
    setSelectedTags(prev => [...prev, tag]);
  };

  const handleTagRemove = (tagToRemove: Tag) => {
    setSelectedTags(prev => 
      prev.filter(tag => 
        !(tag.category === tagToRemove.category && tag.value === tagToRemove.value)
      )
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setIsSubmitting(true);
    try {
      let avatarUrl = user.avatar;
      let coverUrl = user.coverPhoto;

      if (selectedAvatar) {
        avatarUrl = await uploadProfileImage(selectedAvatar, user.id);
      }

      if (selectedCover) {
        coverUrl = await uploadCoverImage(selectedCover, user.id);
      }

      await updateProfile({
        ...formData,
        avatar: avatarUrl,
        coverPhoto: coverUrl,
        tags: selectedTags
      });

      toast.success('Profile updated successfully');
      navigate('/profile');
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error('Failed to update profile');
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClass = "appearance-none block w-full px-3 py-2 border border-gray-600 bg-gray-800 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-gray-100";
  const labelClass = "block text-sm font-medium text-gray-300";

  return (
    <div className="min-h-screen bg-gray-900 pt-20 pb-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-800 rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold text-white mb-6">Edit Profile</h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Cover Photo */}
            <div>
              <label className={labelClass}>Cover Photo</label>
              <div className="mt-2 relative">
                <div 
                  className="h-48 rounded-lg overflow-hidden bg-gray-700 relative"
                  onClick={() => coverInputRef.current?.click()}
                >
                  {coverPreview ? (
                    <img
                      src={coverPreview}
                      alt="Cover"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="flex items-center justify-center h-full">
                      <Camera className="h-12 w-12 text-gray-500" />
                    </div>
                  )}
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity flex items-center justify-center">
                    <span className="text-white font-medium">Change Cover Photo</span>
                  </div>
                </div>
                <input
                  ref={coverInputRef}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleFileSelect('cover', e)}
                />
              </div>
            </div>

            {/* Profile Photo */}
            <div>
              <label className={labelClass}>Profile Photo</label>
              <div className="mt-2 flex items-center space-x-4">
                <div className="relative">
                  <img
                    src={avatarPreview || 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=150&h=150'}
                    alt="Profile"
                    className="w-32 h-32 rounded-full object-cover cursor-pointer"
                    onClick={() => avatarInputRef.current?.click()}
                  />
                  <button
                    type="button"
                    className="absolute bottom-0 right-0 bg-indigo-600 p-2 rounded-full hover:bg-indigo-700 focus:outline-none"
                    onClick={() => avatarInputRef.current?.click()}
                  >
                    <Camera className="h-5 w-5 text-white" />
                  </button>
                </div>
                <input
                  ref={avatarInputRef}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleFileSelect('avatar', e)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="displayName" className={labelClass}>Display Name</label>
              <input
                type="text"
                id="displayName"
                name="displayName"
                value={formData.displayName}
                onChange={handleChange}
                className={inputClass}
              />
            </div>

            <div>
              <label htmlFor="bio" className={labelClass}>Bio</label>
              <textarea
                id="bio"
                name="bio"
                rows={4}
                value={formData.bio}
                onChange={handleChange}
                className={`${inputClass} resize-none`}
              />
            </div>

            <div>
              <label htmlFor="location" className={labelClass}>Location</label>
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className={inputClass}
              />
            </div>

            <div className="space-y-4">
              <h3 className={labelClass}>Social Links</h3>
              
              <div>
                <label htmlFor="social.onlyFans" className={labelClass}>OnlyFans</label>
                <input
                  type="text"
                  id="social.onlyFans"
                  name="social.onlyFans"
                  value={formData.socialLinks.onlyFans}
                  onChange={handleChange}
                  className={inputClass}
                />
              </div>

              <div>
                <label htmlFor="social.patreon" className={labelClass}>Patreon</label>
                <input
                  type="text"
                  id="social.patreon"
                  name="social.patreon"
                  value={formData.socialLinks.patreon}
                  onChange={handleChange}
                  className={inputClass}
                />
              </div>

              <div>
                <label htmlFor="social.twitter" className={labelClass}>Twitter</label>
                <input
                  type="text"
                  id="social.twitter"
                  name="social.twitter"
                  value={formData.socialLinks.twitter}
                  onChange={handleChange}
                  className={inputClass}
                />
              </div>

              <div>
                <label htmlFor="social.instagram" className={labelClass}>Instagram</label>
                <input
                  type="text"
                  id="social.instagram"
                  name="social.instagram"
                  value={formData.socialLinks.instagram}
                  onChange={handleChange}
                  className={inputClass}
                />
              </div>

              <div>
                <label htmlFor="social.website" className={labelClass}>Website</label>
                <input
                  type="text"
                  id="social.website"
                  name="social.website"
                  value={formData.socialLinks.website}
                  onChange={handleChange}
                  className={inputClass}
                />
              </div>
            </div>

            <div>
              <label className={labelClass}>Tags</label>
              <div className="mt-2">
                <TagSelector
                  selectedTags={selectedTags}
                  onTagSelect={handleTagSelect}
                  onTagRemove={handleTagRemove}
                />
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/profile')}
                className="px-4 py-2 border border-gray-600 rounded-lg text-gray-300 hover:bg-gray-700 focus:outline-none"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}