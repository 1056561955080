import React, { useEffect, useState } from 'react';
import { auth, db } from '../lib/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

export default function FirebaseTest() {
  const [testResult, setTestResult] = useState<string>('Running tests...');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function runTests() {
      try {
        // Test 1: Verify Firebase is initialized
        if (!auth || !db) {
          throw new Error('Firebase services not initialized');
        }
        setTestResult(prev => prev + '\n✅ Firebase initialized');

        // Test 2: Try to create a test user
        const testEmail = `test${Date.now()}@example.com`;
        const testPassword = 'testPassword123';

        const userCredential = await createUserWithEmailAndPassword(
          auth,
          testEmail,
          testPassword
        );

        if (userCredential.user) {
          setTestResult(prev => prev + '\n✅ Auth working - Test user created');

          // Test 3: Try to write to Firestore
          await setDoc(doc(db, 'test', userCredential.user.uid), {
            email: testEmail,
            timestamp: new Date()
          });

          setTestResult(prev => prev + '\n✅ Firestore working - Test document created');
          
          // Clean up
          await userCredential.user.delete();
          setTestResult(prev => prev + '\n✅ Test user cleaned up');
        }

        setTestResult(prev => prev + '\n\n🎉 All tests passed!');
      } catch (err) {
        console.error('Test failed:', err);
        setError(err instanceof Error ? err.message : 'Unknown error');
        setTestResult(prev => prev + '\n❌ Tests failed');
      }
    }

    runTests();
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
      <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-bold text-white mb-4">Firebase Configuration Test</h2>
        <pre className="bg-gray-900 p-4 rounded-lg text-sm font-mono whitespace-pre-wrap overflow-auto max-h-96">
          <code className="text-green-400">{testResult}</code>
          {error && (
            <code className="text-red-400">
              {'\n\nError details:\n'}{error}
            </code>
          )}
        </pre>
      </div>
    </div>
  );
}