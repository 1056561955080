import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useFormValidation } from '../hooks/useFormValidation';
import Button from './ui/Button';
import FormField from './ui/FormField';
import toast from 'react-hot-toast';

interface LoginFormProps {
  onToggleForm: () => void;
}

interface LoginFormValues {
  email: string;
  password: string;
}

const validationRules = {
  email: [
    {
      validate: (value: string) => !!value,
      message: 'Email is required',
    },
    {
      validate: (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please enter a valid email address',
    },
  ],
  password: [
    {
      validate: (value: string) => !!value,
      message: 'Password is required',
    },
    {
      validate: (value: string) => value.length >= 6,
      message: 'Password must be at least 6 characters',
    },
  ],
};

export default function LoginForm({ onToggleForm }: LoginFormProps) {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateForm,
  } = useFormValidation<LoginFormValues>(
    {
      email: '',
      password: '',
    },
    validationRules
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      await login(values.email, values.password, rememberMe);
      toast.success('Welcome back!');
      navigate('/explore');
    } catch (error: any) {
      console.error('Login error:', error);
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        toast.error('Invalid email or password');
      } else {
        toast.error('Failed to log in. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <FormField
        label="Email address"
        error={errors.email}
        touched={touched.email}
        required
      >
        <input
          type="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          className="appearance-none block w-full px-3 py-2 border border-gray-600 bg-gray-800 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-gray-100"
          placeholder="you@example.com"
        />
      </FormField>

      <FormField
        label="Password"
        error={errors.password}
        touched={touched.password}
        required
      >
        <input
          type="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          className="appearance-none block w-full px-3 py-2 border border-gray-600 bg-gray-800 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-gray-100"
          placeholder="••••••••"
        />
      </FormField>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-600 bg-gray-800 rounded"
          />
          <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-300">
            Remember me
          </label>
        </div>

        <div className="text-sm">
          <button
            type="button"
            onClick={() => navigate('/forgot-password')}
            className="font-medium text-indigo-400 hover:text-indigo-300"
          >
            Forgot your password?
          </button>
        </div>
      </div>

      <Button
        type="submit"
        isLoading={isLoading}
        loadingText="Signing in..."
        className="w-full"
      >
        Sign in
      </Button>

      <div className="text-sm text-center">
        <button
          type="button"
          onClick={onToggleForm}
          className="font-medium text-indigo-400 hover:text-indigo-300"
        >
          Don't have an account? Sign up
        </button>
      </div>
    </form>
  );
}