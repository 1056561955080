import React, { useState } from 'react';
import { Lock, Image as ImageIcon, Video, X, MoreVertical, Trash2 } from 'lucide-react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import type { Album } from './index';
import AlbumViewer from './AlbumViewer';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import toast from 'react-hot-toast';

interface AlbumGridProps {
  albums: Album[];
  onAlbumSelect: (album: Album) => void;
  currentUserId: string;
  onAlbumDeleted: (albumId: string) => void;
}

export default function AlbumGrid({ albums, onAlbumSelect, currentUserId, onAlbumDeleted }: AlbumGridProps) {
  const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
  const [menuOpen, setMenuOpen] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<string | null>(null);
  const [albumToDelete, setAlbumToDelete] = useState<Album | null>(null);

  const handleAlbumClick = (album: Album) => {
    if (album.isPrivate && album.ownerId !== currentUserId) {
      return; // Don't open private albums for non-owners
    }
    setSelectedAlbum(album);
  };

  const handleDeleteClick = (album: Album, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent album from opening
    setMenuOpen(null);
    setAlbumToDelete(album);
  };

  const handleDeleteConfirm = async () => {
    if (!albumToDelete) return;
    
    setIsDeleting(albumToDelete.id);
    try {
      await deleteDoc(doc(db, 'albums', albumToDelete.id));
      onAlbumDeleted(albumToDelete.id);
      toast.success('Album deleted successfully');
    } catch (error) {
      console.error('Error deleting album:', error);
      toast.error('Failed to delete album');
    } finally {
      setIsDeleting(null);
      setAlbumToDelete(null);
    }
  };

  const toggleMenu = (albumId: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent album from opening
    setMenuOpen(menuOpen === albumId ? null : albumId);
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {albums.map((album) => (
          <div
            key={album.id}
            onClick={() => handleAlbumClick(album)}
            className={`group relative aspect-square rounded-lg overflow-hidden ${
              album.isPrivate && album.ownerId !== currentUserId
                ? 'opacity-50 cursor-not-allowed'
                : 'cursor-pointer'
            } ${isDeleting === album.id ? 'opacity-50 pointer-events-none' : ''}`}
          >
            {/* Menu Button */}
            {album.ownerId === currentUserId && (
              <div className="absolute top-2 right-2 z-10">
                <button
                  onClick={(e) => toggleMenu(album.id, e)}
                  className="p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 transition-colors"
                >
                  <MoreVertical className="h-4 w-4 text-white" />
                </button>
                
                {/* Dropdown Menu */}
                {menuOpen === album.id && (
                  <div className="absolute right-0 mt-1 w-36 bg-gray-800 rounded-lg shadow-lg border border-gray-700 overflow-hidden">
                    <button
                      onClick={(e) => handleDeleteClick(album, e)}
                      className="w-full flex items-center px-4 py-2 text-sm text-red-400 hover:bg-gray-700 transition-colors"
                    >
                      <Trash2 className="h-4 w-4 mr-2" />
                      Delete Album
                    </button>
                  </div>
                )}
              </div>
            )}

            {/* Album Cover */}
            <div className="absolute inset-0 bg-gray-800">
              {album.coverUrl ? (
                <img
                  src={album.coverUrl}
                  alt={album.name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-gray-700 to-gray-800">
                  <span className="text-4xl text-gray-600">
                    {album.name.charAt(0).toUpperCase()}
                  </span>
                </div>
              )}
            </div>

            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-opacity flex items-end">
              <div className="p-4 w-full transform translate-y-full group-hover:translate-y-0 transition-transform">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium text-white truncate">
                    {album.name}
                  </h3>
                  {album.isPrivate && (
                    <Lock className="h-4 w-4 text-white" />
                  )}
                </div>
                <p className="text-sm text-gray-300">
                  {album.mediaCount} {album.mediaCount === 1 ? 'item' : 'items'}
                </p>
              </div>
            </div>

            {/* Loading overlay for deletion */}
            {isDeleting === album.id && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Album Viewer Modal */}
      {selectedAlbum && (
        <AlbumViewer
          album={selectedAlbum}
          onClose={() => setSelectedAlbum(null)}
          isOwner={selectedAlbum.ownerId === currentUserId}
        />
      )}

      {/* Delete Confirmation Dialog */}
      {albumToDelete && (
        <DeleteConfirmDialog
          title="Delete Album"
          message={`Are you sure you want to delete "${albumToDelete.name}"? This action cannot be undone.`}
          onConfirm={handleDeleteConfirm}
          onCancel={() => setAlbumToDelete(null)}
        />
      )}

      {/* Click outside to close menu */}
      {menuOpen && (
        <div
          className="fixed inset-0 z-40"
          onClick={() => setMenuOpen(null)}
        />
      )}
    </>
  );
}