import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './firebase';
import { compressImage } from './imageUtils';

interface UploadOptions {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  useCompression?: boolean;
  contentType?: string;
}

const defaultOptions: UploadOptions = {
  maxSizeMB: 2,
  maxWidthOrHeight: 2048,
  useCompression: true
};

export async function uploadMedia(
  file: File,
  path: string,
  options: UploadOptions = defaultOptions
): Promise<string> {
  try {
    let fileToUpload = file;

    // Validate file size
    if (file.size > 50 * 1024 * 1024) { // 50MB limit
      throw new Error('File size must be less than 50MB');
    }

    // Validate file type
    if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
      throw new Error('Only images and videos are allowed');
    }

    // Compress image if needed
    if (options.useCompression && file.type.startsWith('image/')) {
      fileToUpload = await compressImage(file, {
        maxSizeMB: options.maxSizeMB || 2,
        maxWidthOrHeight: options.maxWidthOrHeight || 2048,
        useWebWorker: true
      });
    }

    // Create a unique filename
    const timestamp = Date.now();
    const filename = `${timestamp}-${file.name}`;
    const storageRef = ref(storage, `${path}/${filename}`);

    // Upload with metadata
    const metadata = {
      contentType: fileToUpload.type,
      customMetadata: {
        originalName: file.name
      }
    };

    // Upload the file
    const snapshot = await uploadBytes(storageRef, fileToUpload, metadata);
    
    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error: any) {
    console.error('Error uploading file:', error);
    if (error.code === 'storage/unauthorized') {
      throw new Error('You do not have permission to upload to this album');
    }
    throw new Error('Failed to upload file. Please try again.');
  }
}

export async function uploadProfileImage(file: File, userId: string): Promise<string> {
  return uploadMedia(file, `profiles/${userId}`, {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 500,
    useCompression: true
  });
}

export async function uploadCoverImage(file: File, userId: string): Promise<string> {
  return uploadMedia(file, `covers/${userId}`, {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useCompression: true
  });
}