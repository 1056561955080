import { useState, useEffect } from 'react';
import { 
  collection,
  query, 
  where, 
  orderBy, 
  onSnapshot, 
  addDoc,
  serverTimestamp,
  doc,
  updateDoc
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { User } from '../contexts/AuthContext';
import type { Message } from '../types/chat';
import toast from 'react-hot-toast';

export function useMessages(conversationId: string | null, currentUser: User | null) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!conversationId || !currentUser) {
      setMessages([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);
    
    try {
      const messagesRef = collection(db, `conversations/${conversationId}/messages`);
      const messagesQuery = query(
        messagesRef,
        orderBy('timestamp', 'asc')
      );

      const unsubscribe = onSnapshot(messagesQuery, 
        (snapshot) => {
          const newMessages = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate() || new Date(),
          })) as Message[];

          setMessages(newMessages);
          setIsLoading(false);

          // Mark messages as read if they're from the other user
          newMessages.forEach(message => {
            if (message.senderId !== currentUser.id && message.status !== 'read') {
              updateDoc(doc(messagesRef, message.id), {
                status: 'read',
                readAt: serverTimestamp()
              });
            }
          });
        },
        (err) => {
          console.error('Error fetching messages:', err);
          setError('Failed to load messages');
          setIsLoading(false);
        }
      );

      return () => unsubscribe();
    } catch (err) {
      console.error('Error setting up messages listener:', err);
      setError('Failed to load messages');
      setIsLoading(false);
    }
  }, [conversationId, currentUser]);

  const sendMessage = async (content: string, media: string[] = []) => {
    if (!conversationId || !currentUser || !content.trim()) return;

    try {
      const messagesRef = collection(db, `conversations/${conversationId}/messages`);
      
      // Create the message
      await addDoc(messagesRef, {
        content: content.trim(),
        senderId: currentUser.id,
        timestamp: serverTimestamp(),
        status: 'sent',
        media,
        conversationId
      });

      // Update conversation's last message
      await updateDoc(doc(db, 'conversations', conversationId), {
        lastMessage: content.trim(),
        lastMessageAt: serverTimestamp(),
        lastMessageRead: false
      });
    } catch (err) {
      console.error('Error sending message:', err);
      toast.error('Failed to send message');
      throw err;
    }
  };

  return {
    messages,
    isLoading,
    error,
    sendMessage
  };
}