import React, { useState, useEffect } from 'react';
import { X, Image as ImageIcon, Video, Lock, MoreVertical, Trash2 } from 'lucide-react';
import { collection, query, where, orderBy, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import type { Album } from './index';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import toast from 'react-hot-toast';

interface Media {
  id: string;
  url: string;
  type: 'image' | 'video';
  filename: string;
  createdAt: Date;
}

interface AlbumViewerProps {
  album: Album;
  onClose: () => void;
  isOwner: boolean;
}

export default function AlbumViewer({ album, onClose, isOwner }: AlbumViewerProps) {
  const [media, setMedia] = useState<Media[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState<string | null>(null);
  const [mediaToDelete, setMediaToDelete] = useState<Media | null>(null);
  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const mediaRef = collection(db, 'media');
        const q = query(
          mediaRef,
          where('albumId', '==', album.id),
          orderBy('createdAt', 'desc')
        );

        const snapshot = await getDocs(q);
        const mediaData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        })) as Media[];

        setMedia(mediaData);

        // Update album cover with first media item if none exists
        if (!album.coverUrl && mediaData.length > 0) {
          const coverUrl = mediaData[0].url;
          await updateDoc(doc(db, 'albums', album.id), {
            coverUrl
          });
        }
      } catch (error) {
        console.error('Error fetching media:', error);
        toast.error('Failed to load media');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMedia();
  }, [album]);

  const handleDeleteClick = (media: Media, e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuOpen(null);
    setMediaToDelete(media);
  };

  const handleDeleteConfirm = async () => {
    if (!mediaToDelete) return;

    setIsDeleting(mediaToDelete.id);
    try {
      await deleteDoc(doc(db, 'media', mediaToDelete.id));
      
      // Update media count in album
      await updateDoc(doc(db, 'albums', album.id), {
        mediaCount: album.mediaCount - 1
      });

      // Update local state
      setMedia(prev => prev.filter(m => m.id !== mediaToDelete.id));
      toast.success('Media deleted successfully');
    } catch (error) {
      console.error('Error deleting media:', error);
      toast.error('Failed to delete media');
    } finally {
      setIsDeleting(null);
      setMediaToDelete(null);
    }
  };

  const toggleMenu = (mediaId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuOpen(menuOpen === mediaId ? null : mediaId);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-lg w-full max-w-6xl max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <div className="flex items-center space-x-3">
            <h2 className="text-xl font-bold text-white">{album.name}</h2>
            {album.isPrivate && (
              <div className="flex items-center space-x-1 text-gray-400">
                <Lock className="h-4 w-4" />
                <span className="text-sm">Private</span>
              </div>
            )}
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-700 rounded-full transition-colors"
          >
            <X className="h-5 w-5 text-gray-400" />
          </button>
        </div>

        {/* Media Grid */}
        <div className="flex-1 overflow-y-auto p-4">
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
            </div>
          ) : media.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {media.map((item) => (
                <div
                  key={item.id}
                  className={`relative aspect-square rounded-lg overflow-hidden group ${
                    isDeleting === item.id ? 'opacity-50 pointer-events-none' : ''
                  }`}
                >
                  {/* Menu Button (only for owner) */}
                  {isOwner && (
                    <div className="absolute top-2 right-2 z-10 opacity-0 group-hover:opacity-100 transition-opacity">
                      <button
                        onClick={(e) => toggleMenu(item.id, e)}
                        className="p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 transition-colors"
                      >
                        <MoreVertical className="h-4 w-4 text-white" />
                      </button>
                      
                      {/* Dropdown Menu */}
                      {menuOpen === item.id && (
                        <div className="absolute right-0 mt-1 w-36 bg-gray-800 rounded-lg shadow-lg border border-gray-700 overflow-hidden">
                          <button
                            onClick={(e) => handleDeleteClick(item, e)}
                            className="w-full flex items-center px-4 py-2 text-sm text-red-400 hover:bg-gray-700 transition-colors"
                          >
                            <Trash2 className="h-4 w-4 mr-2" />
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {item.type === 'image' ? (
                    <img
                      src={item.url}
                      alt={item.filename}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <video
                      src={item.url}
                      className="w-full h-full object-cover"
                      controls
                    />
                  )}
                  <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-opacity flex items-center justify-center">
                    <div className="transform scale-0 group-hover:scale-100 transition-transform">
                      {item.type === 'image' ? (
                        <ImageIcon className="h-8 w-8 text-white" />
                      ) : (
                        <Video className="h-8 w-8 text-white" />
                      )}
                    </div>
                  </div>

                  {/* Loading overlay for deletion */}
                  {isDeleting === item.id && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-gray-400">
              <ImageIcon className="h-12 w-12 mb-4" />
              <p>No media in this album yet</p>
            </div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      {mediaToDelete && (
        <DeleteConfirmDialog
          title="Delete Media"
          message="Are you sure you want to delete this media? This action cannot be undone."
          onConfirm={handleDeleteConfirm}
          onCancel={() => setMediaToDelete(null)}
        />
      )}

      {/* Click outside to close menu */}
      {menuOpen && (
        <div
          className="fixed inset-0 z-40"
          onClick={() => setMenuOpen(null)}
        />
      )}
    </div>
  );
}