import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Filter, X, Search } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import SearchMap from './SearchMap';
import { tagCategories } from './TagSelector';
import { collection, query, getDocs, orderBy, where, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { FirebaseUser } from '../types/firebase';
import UserCard from './UserCard';
import toast from 'react-hot-toast';

function SearchPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showFilters, setShowFilters] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [users, setUsers] = useState<FirebaseUser[]>([]);
  const [following, setFollowing] = useState<string[]>([]);
  const [followerCounts, setFollowerCounts] = useState<Record<string, number>>({});
  const [isLoading, setIsLoading] = useState(true);
  const locationInputRef = useRef<HTMLInputElement>(null);
  const filtersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (locationInputRef.current && window.google) {
      const autocomplete = new google.maps.places.Autocomplete(locationInputRef.current, {
        types: ['(cities)']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          setSelectedLocation(place.formatted_address);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!user) return;

    const fetchUsers = async () => {
      try {
        const usersQuery = query(
          collection(db, 'users'),
          orderBy('createdAt', 'desc')
        );
        
        const querySnapshot = await getDocs(usersQuery);
        const fetchedUsers = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() } as FirebaseUser))
          .filter(fetchedUser => fetchedUser.id !== user.id);

        setUsers(fetchedUsers);

        // Fetch follower counts for each user
        const counts: Record<string, number> = {};
        await Promise.all(
          fetchedUsers.map(async (fetchedUser) => {
            const followersQuery = query(
              collection(db, 'follows'),
              where('followingId', '==', fetchedUser.id)
            );
            const followersSnap = await getDocs(followersQuery);
            counts[fetchedUser.id] = followersSnap.size;
          })
        );
        setFollowerCounts(counts);

        // Fetch current user's following status
        const followingQuery = query(
          collection(db, 'follows'),
          where('followerId', '==', user.id)
        );
        const followingSnap = await getDocs(followingQuery);
        setFollowing(followingSnap.docs.map(doc => doc.data().followingId));
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Failed to load users');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [user]);

  const handleFollow = async (userId: string) => {
    if (!user) {
      toast.error('Please sign in to follow users');
      return;
    }

    try {
      const followId = `${user.id}_${userId}`;
      const followRef = doc(db, 'follows', followId);

      if (following.includes(userId)) {
        // Unfollow
        await deleteDoc(followRef);
        setFollowing(prev => prev.filter(id => id !== userId));
        setFollowerCounts(prev => ({
          ...prev,
          [userId]: Math.max(0, (prev[userId] || 0) - 1)
        }));
        toast.success('Unfollowed user');
      } else {
        // Follow
        await setDoc(followRef, {
          followerId: user.id,
          followingId: userId,
          createdAt: new Date()
        });
        setFollowing(prev => [...prev, userId]);
        setFollowerCounts(prev => ({
          ...prev,
          [userId]: (prev[userId] || 0) + 1
        }));
        toast.success('Following user');
      }
    } catch (error) {
      console.error('Error following user:', error);
      toast.error('Failed to update follow status');
    }
  };

  const handleMessage = (userId: string) => {
    if (!user) {
      toast.error('Please sign in to send messages');
      return;
    }
    navigate('/messages', { state: { userId } });
  };

  const filteredUsers = users.filter(u => {
    const matchesLocation = !selectedLocation || 
      u.location?.toLowerCase().includes(selectedLocation.toLowerCase());
    
    const matchesTags = selectedTags.length === 0 || 
      selectedTags.every(tag => u.tagIds?.includes(tag));
    
    return matchesLocation && matchesTags;
  });

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Mobile Filter Button */}
        <div className="lg:hidden mb-4">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="w-full flex items-center justify-center space-x-2 px-4 py-2 bg-gray-800 rounded-lg text-gray-200 hover:bg-gray-700 transition-colors"
          >
            {showFilters ? (
              <>
                <X className="h-5 w-5" />
                <span>Hide Filters</span>
              </>
            ) : (
              <>
                <Filter className="h-5 w-5" />
                <span>Show Filters</span>
              </>
            )}
          </button>
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          {/* Filters Panel */}
          <div
            ref={filtersRef}
            className={`${
              showFilters ? 'fixed inset-0 z-40 lg:relative lg:inset-auto' : 'hidden lg:block'
            } lg:w-80 bg-gray-900 lg:bg-transparent`}
          >
            <div className="h-full overflow-auto p-4 lg:p-0">
              <div className="space-y-6">
                {/* Location Filter */}
                <div className="bg-gray-800 rounded-lg p-4">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium text-white">Location</h3>
                    <button
                      onClick={() => setShowMap(true)}
                      className="text-sm text-gray-400 hover:text-indigo-400 transition-colors flex items-center space-x-1"
                    >
                      <MapPin className="h-4 w-4" />
                      <span>show on map</span>
                    </button>
                  </div>
                  <div className="space-y-2">
                    <div className="relative">
                      <input
                        ref={locationInputRef}
                        type="text"
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                        placeholder="Filter by location..."
                        className="w-full pl-10 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-gray-100 placeholder-gray-400 focus:outline-none focus:border-indigo-500"
                      />
                      <MapPin className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    </div>
                    <p className="text-xs text-gray-400">
                      Enter a location to filter creators
                    </p>
                  </div>
                </div>

                {/* Tags Filter */}
                <div className="bg-gray-800 rounded-lg p-4">
                  <h3 className="text-lg font-medium text-white mb-4">Tags</h3>
                  <div className="space-y-4">
                    {Object.entries(tagCategories).map(([category, tags]) => (
                      <div key={category} className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-300">{category}</h4>
                        <div className="flex flex-wrap gap-2">
                          {tags.map((tag) => (
                            <button
                              key={tag}
                              onClick={() => {
                                if (selectedTags.includes(tag)) {
                                  setSelectedTags(prev => prev.filter(t => t !== tag));
                                } else {
                                  setSelectedTags(prev => [...prev, tag]);
                                }
                              }}
                              className={`px-3 py-1 rounded-full text-sm ${
                                selectedTags.includes(tag)
                                  ? 'bg-indigo-600 text-white'
                                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                              }`}
                            >
                              {tag}
                            </button>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* User Grid */}
          <div className="flex-1">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {filteredUsers.map((otherUser) => (
                <UserCard
                  key={otherUser.id}
                  user={otherUser}
                  onFollow={handleFollow}
                  onMessage={handleMessage}
                  isFollowing={following.includes(otherUser.id)}
                  followersCount={followerCounts[otherUser.id] || 0}
                />
              ))}
            </div>

            {/* Loading State */}
            {isLoading && (
              <div className="text-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500 mx-auto"></div>
                <p className="mt-4 text-gray-400">Loading users...</p>
              </div>
            )}

            {/* Empty State */}
            {!isLoading && filteredUsers.length === 0 && (
              <div className="text-center py-12">
                <p className="text-gray-400">No users found matching your filters</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Map Modal */}
      {showMap && (
        <SearchMap
          locations={[]}
          onLocationSelect={setSelectedLocation}
          isOpen={showMap}
          onClose={() => setShowMap(false)}
        />
      )}

      {/* Mobile Filters Backdrop */}
      {showFilters && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={() => setShowFilters(false)}
        />
      )}
    </div>
  );
}

export default SearchPage;