import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Search, Plus } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useConversations } from '../../hooks/useConversations';
import ConversationList from './ConversationList';
import MessageThread from './MessageThread';
import NewMessageModal from './NewMessageModal';
import type { Conversation } from '../../types/chat';

export default function Messages() {
  const { user } = useAuth();
  const location = useLocation();
  const { conversations, isLoading, initializeConversation } = useConversations();
  const [activeConversation, setActiveConversation] = useState<Conversation | null>(null);
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (location.state?.userId) {
      const conversation = conversations.find(
        conv => conv.user.id === location.state.userId
      );
      if (conversation) {
        setActiveConversation(conversation);
      } else {
        initializeConversation(location.state.userId);
      }
    }
  }, [location.state, conversations]);

  const handleStartNewMessage = async (userId: string) => {
    await initializeConversation(userId);
    setShowNewMessage(false);
  };

  const filteredConversations = conversations.filter(conv => 
    conv.user.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    conv.user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-7xl mx-auto h-[calc(100vh-4rem)]">
        <div className="flex h-full">
          {/* Conversations Sidebar */}
          <div className="w-96 border-r border-gray-700 flex flex-col bg-gray-800">
            <div className="p-4 border-b border-gray-700">
              <div className="flex space-x-2">
                <div className="relative flex-1">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search messages..."
                    className="w-full pl-10 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
                <button
                  onClick={() => setShowNewMessage(true)}
                  className="p-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                  title="New Message"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </div>
            </div>

            {isLoading ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
              </div>
            ) : (
              <ConversationList
                conversations={filteredConversations}
                activeConversation={activeConversation}
                onSelectConversation={setActiveConversation}
              />
            )}
          </div>

          {/* Chat Area */}
          {activeConversation ? (
            <div className="flex-1 flex flex-col bg-gray-900">
              <MessageThread conversation={activeConversation} currentUser={user} />
            </div>
          ) : (
            <div className="flex-1 flex items-center justify-center bg-gray-900">
              <div className="text-center">
                <h3 className="text-xl font-medium text-gray-400 mb-2">
                  Select a conversation
                </h3>
                <p className="text-gray-500">
                  Choose a conversation from the list or start a new one
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {showNewMessage && (
        <NewMessageModal
          onClose={() => setShowNewMessage(false)}
          onSelectUser={handleStartNewMessage}
        />
      )}
    </div>
  );
}