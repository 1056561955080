import React, { useState, useRef, useEffect } from 'react';
import { Send, Image as ImageIcon } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { useMessages } from '../../hooks/useMessages';
import type { Conversation } from '../../types/chat';
import type { User } from '../../contexts/AuthContext';
import { uploadMedia } from '../../lib/storage';
import toast from 'react-hot-toast';

interface MessageThreadProps {
  conversation: Conversation;
  currentUser: User | null;
}

export default function MessageThread({ conversation, currentUser }: MessageThreadProps) {
  const [newMessage, setNewMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { messages, isLoading, error, sendMessage } = useMessages(
    conversation.id,
    currentUser
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !currentUser) return;

    try {
      await sendMessage(newMessage);
      setNewMessage('');
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (!files.length || !currentUser) return;

    setIsUploading(true);
    try {
      const mediaUrls = await Promise.all(
        files.map(file => uploadMedia(file, `messages/${conversation.id}`, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useCompression: true
        }))
      );

      await sendMessage(newMessage || '📎 Attachment', mediaUrls);
      setNewMessage('');
      toast.success('Media uploaded successfully');
    } catch (error) {
      console.error('Failed to upload media:', error);
      toast.error('Failed to upload media');
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <>
      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => {
          const isOwnMessage = message.senderId === currentUser?.id;

          return (
            <div
              key={message.id}
              className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}
            >
              <div className={`flex items-end space-x-2 max-w-[70%]`}>
                {!isOwnMessage && (
                  <img
                    src={conversation.user.avatar}
                    alt={conversation.user.displayName}
                    className="w-6 h-6 rounded-full"
                  />
                )}
                <div
                  className={`rounded-lg px-4 py-2 ${
                    isOwnMessage
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-700 text-gray-100'
                  }`}
                >
                  {message.media && message.media.length > 0 && (
                    <div className="mb-2 space-y-2">
                      {message.media.map((url, index) => (
                        <img
                          key={index}
                          src={url}
                          alt="Attachment"
                          className="rounded-lg max-w-full"
                        />
                      ))}
                    </div>
                  )}
                  <p>{message.content}</p>
                  <div className="flex items-center justify-end space-x-2 mt-1">
                    <p className="text-xs opacity-75">
                      {formatDistanceToNow(message.timestamp, { addSuffix: true })}
                    </p>
                    {isOwnMessage && (
                      <span className="text-xs opacity-75">
                        {message.status === 'read' ? '✓✓' : '✓'}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {/* Message Input */}
      <form onSubmit={handleSendMessage} className="p-4 border-t border-gray-700">
        <div className="flex items-center space-x-4">
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*,video/*"
            multiple
            className="hidden"
            onChange={handleFileSelect}
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            disabled={isUploading}
            className="p-2 text-gray-400 hover:text-white hover:bg-gray-700 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ImageIcon className="h-5 w-5" />
          </button>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            disabled={isUploading}
            className="flex-1 bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-gray-100 placeholder-gray-400 focus:outline-none focus:border-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
          />
          <button
            type="submit"
            disabled={!newMessage.trim() || isUploading}
            className={`p-2 rounded-full ${
              newMessage.trim() && !isUploading
                ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                : 'bg-gray-700 text-gray-400 cursor-not-allowed'
            }`}
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
      </form>
    </>
  );
}