import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Toast from './components/Toast';
import Navbar from './components/Navbar';
import AuthLayout from './components/AuthLayout';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import ProfileSetup from './components/ProfileSetup';
import Profile from './components/Profile';
import UserProfile from './components/UserProfile';
import EditProfile from './components/EditProfile';
import AccountSettings from './components/AccountSettings';
import SearchPage from './components/SearchPage';
import Messages from './components/Messages';
import FirebaseTest from './components/FirebaseTest';
import About from './components/About';
import PWAInstallPrompt from './components/PWAInstallPrompt';

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  return user ? <>{children}</> : <Navigate to="/login" />;
}

function App() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showProfileSetup, setShowProfileSetup] = useState(false);

  if (showProfileSetup) {
    return (
      <ProfileSetup 
        onComplete={() => {
          setShowProfileSetup(false);
          navigate('/explore', { replace: true });
        }} 
      />
    );
  }

  return (
    <ErrorBoundary>
      <AuthProvider>
        <Toast />
        <PWAInstallPrompt />
        <Routes>
          <Route
            path="/login"
            element={
              <AuthLayout 
                title="Ready to collab?"
                subtitle="SCOUTED"
              >
                <LoginForm onToggleForm={() => navigate('/register')} />
              </AuthLayout>
            }
          />
          <Route
            path="/register"
            element={
              <AuthLayout 
                title="Create your account"
              >
                <RegisterForm 
                  onToggleForm={() => navigate('/login')} 
                  onRegisterSuccess={() => setShowProfileSetup(true)}
                />
              </AuthLayout>
            }
          />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/about" element={<About />} />
          <Route path="/test" element={<FirebaseTest />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <MainApp />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </ErrorBoundary>
  );
}

function MainApp() {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/explore" />} />
        <Route path="/explore" element={<SearchPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/:userId" element={<UserProfile />} />
        <Route path="/profile/edit" element={<EditProfile />} />
        <Route path="/settings" element={<AccountSettings />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/test" element={<FirebaseTest />} />
      </Routes>
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;