import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: "new-scouted.firebasestorage.app",
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

auth.useDeviceLanguage(); // Set language to user's device language

// Configure action code settings for password reset
const actionCodeSettings = {
  url: 'https://myscouted.com/reset-password',
  handleCodeInApp: true,
};

// Log configuration for debugging
console.log('Firebase Auth Configuration:', {
  currentUser: auth.currentUser,
  languageCode: auth.languageCode,
  settings: actionCodeSettings,
  redirectUrl: actionCodeSettings.url
});

export { actionCodeSettings };
export default app;