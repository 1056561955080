import React, { useState, useRef } from 'react';
import { X, Upload, Image } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { collection, addDoc, serverTimestamp, updateDoc, increment, doc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { uploadMedia } from '../../lib/storage';
import type { Album } from './index';
import toast from 'react-hot-toast';

interface UploadMediaModalProps {
  albums: Album[];
  selectedAlbum: Album | null;
  onClose: () => void;
  onUploadComplete: () => void;
}

export default function UploadMediaModal({ 
  albums, 
  selectedAlbum,
  onClose, 
  onUploadComplete 
}: UploadMediaModalProps) {
  const { user } = useAuth();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedAlbumId, setSelectedAlbumId] = useState<string>(selectedAlbum?.id || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFile = (file: File): string | null => {
    if (file.size > 50 * 1024 * 1024) {
      return 'File size must be less than 50MB';
    }
    if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
      return 'Only images and videos are allowed';
    }
    return null;
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const validFiles: File[] = [];
    
    files.forEach(file => {
      const error = validateFile(file);
      if (error) {
        toast.error(`${file.name}: ${error}`);
      } else {
        validFiles.push(file);
      }
    });

    setSelectedFiles(prev => [...prev, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (!user || !selectedAlbumId || selectedFiles.length === 0) {
      toast.error('Please select an album and at least one file');
      return;
    }

    setIsUploading(true);
    let successCount = 0;

    try {
      // Upload each file and create media documents
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        
        try {
          // Upload file to storage
          const mediaUrl = await uploadMedia(file, `albums/${selectedAlbumId}`, {
            maxSizeMB: 50,
            maxWidthOrHeight: 2048,
            useCompression: file.type.startsWith('image/')
          });

          // Create media document
          await addDoc(collection(db, 'media'), {
            albumId: selectedAlbumId,
            ownerId: user.id,
            url: mediaUrl,
            type: file.type.startsWith('image/') ? 'image' : 'video',
            filename: file.name,
            size: file.size,
            createdAt: serverTimestamp()
          });

          successCount++;
        } catch (error) {
          console.error(`Error uploading ${file.name}:`, error);
          toast.error(`Failed to upload ${file.name}`);
        }

        // Update progress
        setUploadProgress(((i + 1) / selectedFiles.length) * 100);
      }

      if (successCount > 0) {
        // Update album media count
        await updateDoc(doc(db, 'albums', selectedAlbumId), {
          mediaCount: increment(successCount),
          updatedAt: serverTimestamp()
        });

        toast.success(`Successfully uploaded ${successCount} files`);
        onUploadComplete();
      }
    } catch (error) {
      console.error('Error uploading media:', error);
      toast.error('Failed to complete upload');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-lg max-w-2xl w-full">
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <h2 className="text-lg font-semibold text-white">Upload Media</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          {/* Album Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Select Album
            </label>
            <select
              value={selectedAlbumId}
              onChange={(e) => setSelectedAlbumId(e.target.value)}
              className="w-full rounded-md bg-gray-700 border-gray-600 text-white focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="">Choose an album</option>
              {albums.map((album) => (
                <option key={album.id} value={album.id}>
                  {album.name} {album.isPrivate ? '(Private)' : ''}
                </option>
              ))}
            </select>
          </div>

          {/* File Selection */}
          <div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*,video/*"
              multiple
              onChange={handleFileSelect}
              className="hidden"
            />
            <button
              onClick={() => fileInputRef.current?.click()}
              className="w-full h-32 border-2 border-dashed border-gray-600 rounded-lg flex flex-col items-center justify-center text-gray-400 hover:text-white hover:border-gray-500 transition-colors"
            >
              <Upload className="h-8 w-8 mb-2" />
              <span>Click to select files</span>
              <span className="text-sm mt-1">or drag and drop them here</span>
            </button>
          </div>

          {/* Selected Files */}
          {selectedFiles.length > 0 && (
            <div className="space-y-2">
              <h3 className="text-sm font-medium text-gray-300">
                Selected Files ({selectedFiles.length})
              </h3>
              <div className="max-h-48 overflow-y-auto space-y-2">
                {selectedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 bg-gray-700 rounded-lg"
                  >
                    <div className="flex items-center space-x-2">
                      <Image className="h-5 w-5 text-gray-400" />
                      <span className="text-sm text-gray-300 truncate">
                        {file.name}
                      </span>
                    </div>
                    <button
                      onClick={() => removeFile(index)}
                      className="text-gray-400 hover:text-white"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Upload Progress */}
          {isUploading && (
            <div className="space-y-2">
              <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
                <div
                  className="h-full bg-indigo-600 transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
              <p className="text-sm text-gray-400 text-center">
                Uploading... {Math.round(uploadProgress)}%
              </p>
            </div>
          )}

          {/* Actions */}
          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-white focus:outline-none"
              disabled={isUploading}
            >
              Cancel
            </button>
            <button
              onClick={handleUpload}
              disabled={isUploading || selectedFiles.length === 0 || !selectedAlbumId}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}