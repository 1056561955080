import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageCircle, UserPlus, Crown } from 'lucide-react';
import type { FirebaseUser } from '../types/firebase';

interface UserCardProps {
  user: FirebaseUser;
  onFollow: (userId: string) => void;
  onMessage: (userId: string) => void;
  isFollowing?: boolean;
  followersCount?: number;
}

export default function UserCard({ 
  user, 
  onFollow, 
  onMessage, 
  isFollowing,
  followersCount = 0
}: UserCardProps) {
  const navigate = useNavigate();

  const handleMessage = () => {
    navigate('/messages', { 
      state: { userId: user.id }
    });
  };

  return (
    <div className="bg-gray-800 rounded-lg overflow-hidden border border-gray-700">
      <div 
        onClick={() => navigate(`/profile/${user.id}`)}
        className="cursor-pointer"
      >
        <div className="aspect-square relative group">
          <img
            src={user.avatar || 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=400&h=400'}
            alt={user.displayName}
            className="w-full h-full object-cover transition-transform group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity flex items-end">
            <div className="p-3 w-full">
              <div className="flex items-center space-x-2">
                <h3 className="text-sm font-semibold text-white truncate">
                  {user.displayName}
                </h3>
                {user.subscriptionTier === 'pro' && (
                  <Crown className="h-3 w-3 text-yellow-400 flex-shrink-0" />
                )}
              </div>
              {user.location && (
                <p className="text-xs text-gray-200 truncate">{user.location}</p>
              )}
              <p className="text-xs text-gray-300 mt-1">
                {followersCount} {followersCount === 1 ? 'follower' : 'followers'}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-2 flex space-x-1">
        <button
          onClick={handleMessage}
          className="flex-1 flex items-center justify-center space-x-1 px-2 py-1.5 bg-gray-700 hover:bg-gray-600 rounded-lg text-white text-sm transition-colors"
        >
          <MessageCircle className="h-3 w-3" />
          <span>Message</span>
        </button>
        <button
          onClick={() => onFollow(user.id)}
          className={`flex-1 flex items-center justify-center space-x-1 px-2 py-1.5 rounded-lg text-sm transition-colors ${
            isFollowing
              ? 'bg-indigo-600 hover:bg-indigo-700 text-white'
              : 'bg-gray-700 hover:bg-gray-600 text-white'
          }`}
        >
          <UserPlus className="h-3 w-3" />
          <span>{isFollowing ? 'Following' : 'Follow'}</span>
        </button>
      </div>
    </div>
  );
}