import React from 'react';
import { Twitter, Instagram, Globe } from 'lucide-react';

interface SocialLinksProps {
  onlyFans?: string;
  patreon?: string;
  twitter?: string;
  instagram?: string;
  website?: string;
}

export default function SocialLinks({ onlyFans, patreon, twitter, instagram, website }: SocialLinksProps) {
  const renderSocialLink = (href: string | undefined, icon: JSX.Element, title: string) => {
    if (!href) return null;

    // Ensure URL has protocol
    const url = href.startsWith('http') ? href : `https://${href}`;
    
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-400 hover:text-indigo-400 transition-colors"
        title={title}
      >
        {icon}
      </a>
    );
  };

  const OnlyFansIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="w-6 h-6"
      fill="currentColor"
    >
      <path d="M12 0C5.375 0 0 5.375 0 12c0 6.627 5.375 12 12 12 6.627 0 12-5.373 12-12 0-6.625-5.373-12-12-12zm0 4.125c4.344 0 7.875 3.531 7.875 7.875 0 4.344-3.531 7.875-7.875 7.875-4.344 0-7.875-3.531-7.875-7.875 0-4.344 3.531-7.875 7.875-7.875zm0 2.625c-2.898 0-5.25 2.352-5.25 5.25 0 2.898 2.352 5.25 5.25 5.25 2.898 0 5.25-2.352 5.25-5.25 0-2.898-2.352-5.25-5.25-5.25z" />
    </svg>
  );

  const PatreonIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="w-6 h-6"
      fill="currentColor"
    >
      <path d="M14.82 2.41c3.96 0 7.18 3.24 7.18 7.21 0 3.96-3.22 7.18-7.18 7.18-3.97 0-7.21-3.22-7.21-7.18 0-3.97 3.24-7.21 7.21-7.21M2 21.6h3.5V2.41H2V21.6z" />
    </svg>
  );

  const hasAnyLinks = onlyFans || patreon || twitter || instagram || website;

  if (!hasAnyLinks) {
    return (
      <p className="text-gray-400 text-sm">No social links added yet</p>
    );
  }

  return (
    <div className="flex space-x-4">
      {renderSocialLink(onlyFans, <OnlyFansIcon />, 'OnlyFans')}
      {renderSocialLink(patreon, <PatreonIcon />, 'Patreon')}
      {renderSocialLink(twitter, <Twitter className="w-6 h-6" />, 'Twitter')}
      {renderSocialLink(instagram, <Instagram className="w-6 h-6" />, 'Instagram')}
      {renderSocialLink(website, <Globe className="w-6 h-6" />, 'Website')}
    </div>
  );
}