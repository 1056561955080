import React, { useState, useEffect, useRef } from 'react';
import { Image, Send, Plus, Lock, Unlock, Folder } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import CreateAlbumModal from './CreateAlbumModal';
import AlbumGrid from './AlbumGrid';
import UploadMediaModal from './UploadMediaModal';
import toast from 'react-hot-toast';

export interface Album {
  id: string;
  name: string;
  description?: string;
  coverUrl?: string;
  isPrivate: boolean;
  mediaCount: number;
  createdAt: Date;
  ownerId: string;
}

interface MediaGalleryProps {
  userId: string;
  isOwnProfile: boolean;
}

export default function MediaGallery({ userId, isOwnProfile }: MediaGalleryProps) {
  const { user } = useAuth();
  const [albums, setAlbums] = useState<Album[]>([]);
  const [showCreateAlbum, setShowCreateAlbum] = useState(false);
  const [showUploadMedia, setShowUploadMedia] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAlbums = async () => {
    try {
      const albumsRef = collection(db, 'albums');
      const q = query(
        albumsRef,
        where('ownerId', '==', userId),
        ...(isOwnProfile ? [] : [where('isPrivate', '==', false)]),
        orderBy('createdAt', 'desc')
      );
      const snapshot = await getDocs(q);
      
      const fetchedAlbums = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate()
      })) as Album[];

      setAlbums(fetchedAlbums);
    } catch (error) {
      console.error('Error fetching albums:', error);
      toast.error('Failed to load albums');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAlbums();
  }, [userId, isOwnProfile]);

  const handleAlbumCreated = (newAlbum: Album) => {
    setAlbums(prev => [newAlbum, ...prev]);
    setShowCreateAlbum(false);
    toast.success('Album created successfully');
  };

  const handleAlbumDeleted = async (albumId: string) => {
    setAlbums(prev => prev.filter(album => album.id !== albumId));
  };

  const handleMediaUploaded = () => {
    fetchAlbums(); // Refresh albums to update media count
    setShowUploadMedia(false);
    toast.success('Media uploaded successfully');
  };

  if (!user) return null;

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-xl font-bold text-white">Media Gallery</h2>
          <p className="text-sm text-gray-400">
            {isOwnProfile ? 'Organize and share your media in albums' : 'Browse media albums'}
          </p>
        </div>
        {isOwnProfile && (
          <div className="flex space-x-4">
            <button
              onClick={() => setShowUploadMedia(true)}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors flex items-center space-x-2"
            >
              <Plus className="h-5 w-5" />
              <span>Upload Media</span>
            </button>
            <button
              onClick={() => setShowCreateAlbum(true)}
              className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center space-x-2"
            >
              <Folder className="h-5 w-5" />
              <span>New Album</span>
            </button>
          </div>
        )}
      </div>

      {/* Albums Grid */}
      {isLoading ? (
        <div className="flex items-center justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
        </div>
      ) : albums.length > 0 ? (
        <AlbumGrid 
          albums={albums} 
          onAlbumSelect={setSelectedAlbum}
          currentUserId={user.id}
          onAlbumDeleted={handleAlbumDeleted}
        />
      ) : (
        <div className="text-center py-12 bg-gray-800 rounded-lg">
          <Folder className="h-12 w-12 text-gray-600 mx-auto mb-4" />
          <p className="text-gray-400">No albums yet</p>
          {isOwnProfile && (
            <button
              onClick={() => setShowCreateAlbum(true)}
              className="mt-4 text-indigo-400 hover:text-indigo-300"
            >
              Create your first album
            </button>
          )}
        </div>
      )}

      {/* Modals */}
      {showCreateAlbum && (
        <CreateAlbumModal
          onClose={() => setShowCreateAlbum(false)}
          onAlbumCreated={handleAlbumCreated}
        />
      )}

      {showUploadMedia && (
        <UploadMediaModal
          albums={albums}
          selectedAlbum={selectedAlbum}
          onClose={() => setShowUploadMedia(false)}
          onUploadComplete={handleMediaUploaded}
        />
      )}
    </div>
  );
}