import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Calendar, Settings } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import SocialLinks from './SocialLinks';
import MediaGallery from './MediaGallery';
import toast from 'react-hot-toast';

function Profile() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [profileData, setProfileData] = useState<any>(null);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!user) return;

      try {
        // Fetch user data
        const userDoc = await getDoc(doc(db, 'users', user.id));
        if (userDoc.exists()) {
          setProfileData({ id: userDoc.id, ...userDoc.data() });
        }

        // Fetch follower count
        const followersQuery = query(
          collection(db, 'follows'),
          where('followingId', '==', user.id)
        );
        const followersSnap = await getDocs(followersQuery);
        setFollowerCount(followersSnap.size);

        // Fetch following count
        const followingQuery = query(
          collection(db, 'follows'),
          where('followerId', '==', user.id)
        );
        const followingSnap = await getDocs(followingQuery);
        setFollowingCount(followingSnap.size);
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast.error('Failed to load profile');
      }
    };

    fetchProfileData();
  }, [user]);

  if (!user || !profileData) return null;

  const showJoinDate = !profileData.hideJoinDate;

  return (
    <div className="min-h-screen bg-gray-900 pt-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Cover Photo */}
        <div className="relative h-64 rounded-xl overflow-hidden mb-4">
          <img
            src={profileData.coverPhoto || 'https://images.unsplash.com/photo-1510784722466-f2aa9c52fff6?auto=format&fit=crop&w=1920&h=400'}
            alt="Cover"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Profile Info */}
        <div className="relative bg-gray-800 rounded-xl shadow-lg p-6 mb-6">
          <div className="flex flex-col md:flex-row md:items-start gap-6">
            <div className="relative -mt-16 md:-mt-20 flex-shrink-0">
              <img
                src={profileData.avatar || 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=150&h=150'}
                alt={profileData.displayName}
                className="w-32 h-32 rounded-xl border-4 border-gray-800 object-cover"
              />
              <div className="absolute -bottom-2 -right-2">
                <button
                  onClick={() => navigate('/profile/edit')}
                  className="p-2 text-gray-400 hover:text-white bg-gray-700 hover:bg-gray-600 rounded-full transition-colors"
                  title="Edit Profile"
                >
                  <Settings className="h-5 w-5" />
                </button>
              </div>
            </div>
            
            <div className="flex-1 min-w-0">
              <div>
                <h1 className="text-2xl font-bold text-white">{profileData.displayName}</h1>
                <p className="text-gray-400">{profileData.username}</p>
              </div>

              {profileData.bio && (
                <p className="mt-4 text-gray-300">{profileData.bio}</p>
              )}

              <div className="flex flex-wrap gap-6 mt-4">
                <div className="flex items-center space-x-1">
                  <span className="font-semibold text-white">{followerCount}</span>
                  <span className="text-gray-400">Followers</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="font-semibold text-white">{followingCount}</span>
                  <span className="text-gray-400">Following</span>
                </div>
              </div>

              <div className="flex flex-wrap gap-4 mt-4">
                {profileData.location && (
                  <div className="flex items-center text-gray-400">
                    <MapPin className="h-4 w-4 mr-1" />
                    <span>{profileData.location}</span>
                  </div>
                )}
                {showJoinDate && (
                  <div className="flex items-center text-gray-400">
                    <Calendar className="h-4 w-4 mr-1" />
                    <span>Joined {new Date(profileData.createdAt).toLocaleDateString()}</span>
                  </div>
                )}
              </div>

              {/* Social Links */}
              <div className="mt-6">
                <SocialLinks {...profileData.socialLinks} />
              </div>
            </div>
          </div>
        </div>

        {/* Media Gallery */}
        <MediaGallery userId={user.id} isOwnProfile={true} />
      </div>
    </div>
  );
}

export default Profile;